import Model from '../../model'

export default class {
  constructor() {
    this.windowSize = {
      w: undefined,
      h: undefined,
      direction: {
        horizontal: false,
        vertical: false
      }
    }
    this.orientation = undefined
    this.bindEvents()
    this.checkSize()
  }

  bindEvents() {
    var timer = false
    window.addEventListener('resize',()=> {
      this.windowSize.direction = {
        horizontal: false,
        vertical: false
      }
      if (timer !== false) {
          clearTimeout(timer)
      }
      timer = setTimeout(()=> {
          this.checkSize()
      }, 50)
    })
    window.addEventListener('orientationchange',()=> {
      this.orientation = (document.documentElement.clientWidth < document.documentElement.clientHeight) ? 'portrait' : 'landscape'
      this.checkOrientationChange()
    })
  }

  checkSize() {
    this.windowSize.direction = {
      horizontal: (this.windowSize.w !== undefined && this.windowSize.w !== document.documentElement.clientWidth) ? true : false,
      vertical: (this.windowSize.h !== undefined && this.windowSize.h !== document.documentElement.clientHeight) ? true : false
    }
    this.windowSize.w = document.documentElement.clientWidth
    this.windowSize.h = document.documentElement.clientHeight
    Model.onWindowResize(this.windowSize)
  }
  checkOrientationChange(){
    Model.onOrientationChange(this.orientation)
  }
}
