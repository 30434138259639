import Util from '../util'
import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.items = this.element.querySelectorAll('[data-role=height-line-item]')
    this.num = 0
    this.clm = (Util.isKeyInObject(this.config,'clm')) ? parseInt(this.config['clm']) : 4
    this.clmSP = (Util.isKeyInObject(this.config,'clm-sp')) ? parseInt(this.config['clm-sp']) : null
    this.bindEvents()
    this.init()
  }
  init(){
    this.reset()
    window.addEventListener('load', () => {
      if(Model.info.isSP) {
        if(this.clmSP) this.heightline()
      } else {
        this.heightline()
      }
    })
  }
  bindEvents(){
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.reset()
      if(Model.info.isSP) {
        if(this.clmSP) this.heightline()
      } else {
        this.heightline()
      }
    })
  }
  reset(){
    for(let i = 0; i < this.items.length; i = i+1) {
      this.items[i].style.height = 'auto'
    }
  }
  heightline(){
    let itemNum = this.items.length // アイテム数：7
    let maxHeight = 0
    let objHeights = []
    let objMaxHeights = []
    let clm = (Model.info.isSP && this.clmSP) ? this.clmSP  : this.clm
    let rowNum = Math.floor(itemNum / clm) + 1 // 2行
    let lastInNum = itemNum % clm // 最終行：3個

    for(let i = 0, currentRow = 1; i < itemNum; i = i+1) {
      objHeights.push(this.items[i].clientHeight)

      if((i % clm === (clm - 1) && currentRow < rowNum) || (i % clm === (lastInNum - 1) && currentRow === rowNum)) {
        objMaxHeights.push(Math.max.apply(null, objHeights))
        objHeights.length = 0
        currentRow = currentRow+1;
      }
    }

    for(let i = 0, currentRow = 0; i < this.items.length; i = i+1) {
      this.items[i].style.height = objMaxHeights[currentRow] + 'px'

      if((i % clm === (clm - 1) && currentRow < rowNum) || (i % clm === (lastInNum - 1) && currentRow === rowNum)) {
        currentRow = currentRow+1;
      }
    }
  }
}
