export default class {
  constructor(element, config) {
    this.element = element
    this.bindEvents()
  }
  bindEvents() {
    for (let i = 0; i < this.element.children.length; i++) {
      const elementChild = this.element.children[i];
      elementChild.childNodes[0].addEventListener('mouseenter', (e) => {
        const h = e.target.getAttribute('data-hover')
        this.parentAddData(h)
      })
      elementChild.childNodes[0].addEventListener('mouseleave', () => {
        this.parentRemoveData()
      })
    }
  }
  parentAddData(hoverChildren) {
    const hoverItem = hoverChildren
    this.element.setAttribute('data-hover-item', hoverItem)
  }
  parentRemoveData() {
    this.element.setAttribute('data-hover-item', '')
  }
}
