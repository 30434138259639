
export default class {
  constructor(element, config){
    this.element = element
    this.config = config
    this.init()
  }
  init(){
    let wrap = document.createElement('div')
        wrap.classList.add('u-overflow-scroll')
    let inner = document.createElement('div')
        inner.classList.add('r-inner')
        if(this.config) inner.classList.add(this.config)
    let element = this.wrapping(this.element, wrap)
        this.wrapping(element, inner)
  }
  wrapping(element, wrapper) {
    element.parentNode.insertBefore(wrapper, element)
    return wrapper.appendChild(element)
  }
}
