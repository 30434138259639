import Util from '../util'
import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.items = this.element.querySelectorAll('[data-role=qa-increment-itm]')
    this.init()
  }

  init() {
    for(let i = 0; i < this.items.length; i++) {
      let targets = this.items[i].querySelectorAll('[data-role=qa-increment-target]')

      for(let j = 0; j < targets.length; j++) {
        targets[j].setAttribute('data-num', i + 1)
      }
    }
  }
}
