import Model from '../model'

export default class {
  constructor(){
    this.el = {
      button: document.querySelector('[data-role=header-search-open-button]'),
      search: document.querySelector('[data-role=header-search]'),
      submit: document.querySelector('[data-role=header-search] [type="submit"]'),
      textfield: document.querySelector('[data-role=header-search-textfield]'),
      globalnav: document.querySelector('.l-header__nav__btn'),
      html: document.getElementsByTagName('html')[0]
    }
    this.e = {
      click: (Model.info.ua.Tablet) ? 'touchend' : 'click',
      enter: (Model.info.ua.Tablet) ? 'touchend' : 'mouseenter',
      leave: (Model.info.ua.Tablet) ? 'mouseleave touchend' : 'mouseleave',
      focus: (Model.info.IE.IE11 || Model.info.IE.Edge) ? 'focus' : 'DOMFocusIn',
      blur: (Model.info.IE.IE11 || Model.info.IE.Edge) ? 'blur' : 'DOMFocusOut'
    }
    if(this.el.button) {
      this.init()
      this.bindEvents()
    }
  }

  init(){

  }

  bindEvents(){
    this.el.button.addEventListener('click', (e)=>{
      e.stopPropagation()
      this.el.search.classList.add('is-open')
      this.el.textfield.focus()
      this.el.globalnav.setAttribute('aria-expanded', 'false')
      this.el.html.classList.remove('is-gnav-open')
    })
    this.el.submit.addEventListener(this.e.focus, (e)=>{
      e.stopPropagation()
      if(!this.el.search.classList.contains('is-open')) {
        this.el.search.classList.add('is-open')
        this.el.textfield.focus()
      }
    })
    this.el.textfield.addEventListener(this.e.blur, (e)=>{
      if(this.el.search.classList.contains('is-open')) {
        if(!Model.info.IE.IE11 && !Model.info.IE.Edge) this.el.submit.focus()
      }
      this.el.search.classList.remove('is-open')
    })
  }
}
