import Model from '../model'

export default class {
// -----------------------------------------------------------------
//
// @method Components.validateInput()
// pattern属性にもとづいて入力を制御する
// -----------------------------------------------------------------
  constructor() {
    this.init()
  }

  init() {
    this.bindEvents()
  }
  bindEvents() {
    document.addEventListener('keydown', (e)=>{
      if(e.target.dataset.module === 'validate-input') {
        this.validate(e)
      }
    })
  }
  validate(e){
    let pattern = e.target.pattern
    let key = e.keyCode || e.which
    // 半角数字のみ
    if(pattern.startsWith('^[0-9]')) {
      if((key >= 65 && key <= 90) || (key >= 106 && key <= 111) || (key >= 186 && key <= 226)) e.preventDefault()
    }
    // 半角数字のみ
    if(pattern.startsWith('^[0-9A-Za-z]')) {
      if((key >= 106 && key <= 108) || (key >= 186 && key <= 226)) e.preventDefault()
    }
  }
}
