import Model from '../../model'

export default class {
  constructor() {
    this.touch = {
      start: undefined,
      end: undefined
    }
    this.bindEvents()
    this.check()
  }

  bindEvents() {
    window.addEventListener('touchstart',()=> {
      this.check('start')
    })
    window.addEventListener('touchend',()=> {
      this.check('end')
    })
  }

  check(state) {
    this.touch.start = (state === 'start') ? true : false
    this.touch.end = (state === 'end') ? true : false
    Model.onTouch(this.touch)
  }

}
