import Model from '../model'

const Util = new class Util {

// -----------------------------------------------------------------
//
// @method Util.escapeHTML()
// @method Util.escapeHTMLliteral()
//
// -----------------------------------------------------------------

  escapeHTML(str) {
    str = String(str)
    str = str.replace(/&/g, '&amp;')
    str = str.replace(/</g, '&lt;')
    str = str.replace(/>/g, '&gt;')
    str = str.replace(/"/g, '&quot;')
    str = str.replace(/'/g, '&#39;')
    return str
  }

  escapeHTMLliteral() {
    var raw = String.raw.apply(null, arguments)
    var safe = raw.replace(/&/g, '&').replace(/>/g, '>').replace(/"/g, '"').replace(/'/g, '\'')
    return safe
  }

// -----------------------------------------------------------------
//
// @method Util.touchScrollEvent()
// タッチイベントのスクロール判定
// -----------------------------------------------------------------
// TODO: 未精査

  touchScrollEvent() {
    var touchScrollValue
    let pageY, startPageY
    this.touchScrollValue = {
      isAnimated : false,
      isDirection : 'down',
      isMoveVal : 0
    }
    document.addEventListener('touchstart', (e)=> {
      if(vpSP) {
        this.touchScrollValue.isAnimated = true,
        pageY = document.body.scrollTop,
        startPageY = pageY
      }
    })
    document.addEventListener('touchend', (e)=> {
      if(vpSP) {
        this.touchScrollValue.isAnimated = false
      }
    })
    let timer = false
    window.addEventListener('scroll', ()=>{
      if(vpSP) {
        if (timer !== false) {
          clearTimeout(timer)
        }
        timer = setTimeout(()=> {
          pageY = document.body.scrollTop
          if(startPageY > pageY) {
            this.touchScrollValue.isDirection = 'up'
          } else if(startPageY < pageY) {
            this.touchScrollValue.isDirection = 'down'
          }
          this.touchScrollValue.isMoveVal = Math.abs(startPageY - pageY)
        }, 80)
      }
    })

  }

// -----------------------------------------------------------------
//
// @method Util.getCookie()
// Cookie取得
// -----------------------------------------------------------------

  getCookie(key) {
    var result = []
    var cookies = document.cookie
    if(cookies !== ''){
      var cookieArray = cookies.split(';')
      for(var i = 0; i < cookieArray.length; i++){
        var cookie = cookieArray[i].split('=')
        if(cookie[0].indexOf(key) !== -1) result = decodeURIComponent(cookie[1])
      }
    }
    if(result.length > 0) return result
  }


// -----------------------------------------------------------------
//
// @method Util.setCookie()
// Cookie設定
// -----------------------------------------------------------------

  setCookie(key, data, period) {
    var cookies = `${key}=${data};`
    var expire = new Date()
    expire.setTime( expire.getTime() + 1000 * 3600 * 24 * period)
    expire.toGMTString()
    cookies += (period) ? ` path=/; expires=${expire};` : ` path=/;`
    document.cookie = cookies
  }


// -----------------------------------------------------------------
//
// @method Util.getJSON()
// JSON取得
// -----------------------------------------------------------------

  getJSON(url){
    let Httpreq = new XMLHttpRequest()
        Httpreq.open("GET", url, false)
        Httpreq.send(null)
    return JSON.parse(Httpreq.responseText)
  }

  // -----------------------------------------------------------------
  //
  // @method Util.isKeyInObject(obj, key)
  // data-config内 key判定
  // -----------------------------------------------------------------


  isKeyInObject(obj, key) {
    if(obj !== null && typeof obj === 'object') {
      return Object.keys(obj).some(v => v == key)
    } else {
      return false
    }
  }


  // -----------------------------------------------------------------
  //
  // @method Util.nextAll()
  // jQuery.nextAll関数
  // -----------------------------------------------------------------

  nextAll(node, selector) {
    var list = [];
    var next = node.nextElementSibling;

    while (next && next.nodeType === 1) {
      list.push(next);
      next = next.nextElementSibling;
    }

    if (selector) {
      var node = [].slice.call(document.querySelectorAll(selector));

      list = list.filter(function(item) {
        return node.indexOf(item) !== -1;
      });
    }

    return list;
  }

  // -----------------------------------------------------------------
  //
  // @method Util.getParam()
  // パラメータ取得
  // -----------------------------------------------------------------

  getParam(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, "\\$&")
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

}

export default Util
