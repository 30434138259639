import EventEmitter from 'wolfy87-eventemitter'


const Model = new class Model {
  constructor() {
    this.event = new EventEmitter()
    this.state = {
      windowsize: {
        w: undefined,
        h: undefined
      },
      // TODO: スワイプイベントは発行されていないため、後で制作
      swipe: {
        x : undefined,
        y: undefined,
        directionX : undefined,
        directionY: undefined
      },
      touch: {
        start : undefined,
        end : undefined
      },
      scroll: {
        top : undefined,
        bottom : undefined,
        moving : undefined,
        direction : undefined
      },
      orientation: {
        portrait: false,
        landscape: false
      }
    }
    this.eventName = {
      on_window_resize: 'ON_WINDOW_RESIZE',
      on_orientation_change: 'ON_ORIENTATION_CHANGE',
      on_scroll: 'ON_SCROLL',
      on_swipe: 'ON_SWIPE',
      on_touch: 'ON_TOUCH',
      on_close_modalwindow: 'ON_CLOSE_MODALWINDOW'
    }
    this.info = {
      ua: undefined,
      IE: undefined,
      isSP: false
    }
  }

  onWindowResize(windowsize) {
    this.state.windowsize = windowsize
    this.dispatch(this.eventName.on_window_resize)
  }

  onOrientationChange(orientation){
    this.state.orientation = {
      portrait: (orientation === 'portrait') ? true : false,
      landscape: (orientation === 'landscape') ? true : false
    }
    this.dispatch(this.eventName.on_orientation_change)
  }

  onScrollEvent(scroll) {
    this.state.scroll = scroll
    this.dispatch(this.eventName.on_scroll)
  }

  onSwipe(swipe) {
    this.state.swipe = swipe
    this.dispatch(this.eventName.on_swipe)
  }

  onTouch(state) {
    this.state.touch = state
    this.dispatch(this.eventName.on_touch)
  }

  onCarouseSlide(state) {
    this.state.carousel = state
    this.dispatch(this.eventName.on_carousel_state)
  }

  onCloseModalWindow(events) {
    this.dispatch(this.eventName.on_close_modalwindow)
  }

  onCheckUA(o) {
    this.info.ua = o.ua
    this.info.IE = o.IE
  }
  onCheckVP(state) {
    this.info.isSP = state
  }

  dispatch(ev, str) {
    this.event.emit(ev, str)
  }

}

export default Model
