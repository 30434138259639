// TODO: 要不要を検討
//画像にダミーと付与するやつ

export default class {
  constructor(){
    this.dummyOutput()
  }
  dummyOutput(){
    this.el = document.querySelectorAll('.is-dummy')
    for(let i=0;i < this.el.length; i=i+1) {
      let div = document.createElement('div')
          div.textContent = 'ダミー'
          div.classList.add('r-dummy__content')
          div.style.backgroundColor = 'rgba(0,0,0,.5)'
          div.style.color = '#fff'
          div.style.fontSize = '10px'
          div.style.display = 'inline-block'
          div.style.position = 'absolute'
          div.style.top = '0'
          div.style.right = '0'
          div.style.zIndex = '1'
          div.style.padding = '2px 6px'

      $(this.el[i]).wrap('<div class="r-dummy__wrap" />');
      $(this.el[i]).parent('.r-dummy__wrap').css('position', 'relative').append(div);
    }
  }
}
