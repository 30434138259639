import Model from '../model'

export default class {
  constructor(el) {
    this.$el = $(el)
    this.flag = false
    this.init()
    this.judge()
    this.bindEvents()
    this.WindowClose()
  }
  init() {
    this.flag = true
    this.$el.slick({
      autoplay: false,
      arrows: true,
      fade: false,
      dots: false,
      speed: 800,
      infinite: false,
      pauseOnHover: false,
      adaptiveHeight: false,
      cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
      appendArrows: $('.p-education-book-slide-pager')
    })

    //slick
    $('.books-slick-box').on((event, slick)=> {
      $('.current').text(slick.currentSlide + 1)
    })
    //ページ枚数表示
    $('.books-slick-box').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $('.current').text(nextSlide + 1)
    })
  }
  setPosition(){
    // 画面幅処理
    if (Model.info.isSP) {
      $('.p-education-book__detail').height(Model.state.windowsize.h)
    }
    this.$el.slick('setPosition')
  }
  destory(){
    this.flag = false
    this.$el.slick('unslick');
  }

  bindEvents() {
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.judge()
    })
  }
  judge(){
    //縦横判定
    if (Model.state.windowsize.h > Model.state.windowsize.w && Model.info.isSP) {
      $('.p-education-book-close').removeClass('unlock_mode')
      $('.books-slick-box').removeClass('unlock_mode')
      $('.readable_text').addClass('visible')
      $('.p-education-book-slide-pager').addClass('lock_mode')
      $('.p-education-book-slide-pager').find('.slick-arrow').removeClass('unlock_mode')
      $('.p-education-book-slide-pager').find('.p-education-book-slide-counter-text').addClass('lock_mode')
      $('.p-education-book-slide-counter').removeClass('unlock_mode')
      $('.p-education-book-slide-counter').addClass('lock_mode')
      if(this.flag) this.setPosition()
    } else {
      $('.p-education-book-close').addClass('unlock_mode')
      $('.books-slick-box').addClass('unlock_mode')
      $('.readable_text').removeClass('visible')
      $('.p-education-book-slide-pager').removeClass('lock_mode')
      $('.p-education-book-slide-pager').find('.slick-arrow').addClass('unlock_mode')
      $('.p-education-book-slide-pager').find('.p-education-book-slide-counter-text').removeClass('lock_mode')
      $('.p-education-book-slide-counter').addClass('unlock_mode')
      $('.p-education-book-slide-counter').removeClass('lock_mode')

      if(this.flag && Model.info.isSP) this.setPosition()
    }
  }

  WindowClose() {
    //クローズ処理
    $('.close_window').click(function () {
      if (/Chrome/i.test(navigator.userAgent)) {
        window.close()
      } else {
        window.open('about:blank', '_self').close()
      }
    })
  }
}
