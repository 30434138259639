import Model from '../model'

export default class {
  constructor(){
    this.init()
  }

  init(){
    $('a[href^="#"]:not([data-module])').on('click', function(e) {
       e.preventDefault();
       var href= $(this).attr('href');
       var target = $(href == "#" || href == "" || href == "#content-header" ? 'html' : href);
       var position = target.offset().top;
       var isUseTabkey = (document.body.classList.contains('is-user-tabbing')) ? true : false
       var element = (isUseTabkey) ? $('html') : $('html,body')
       element.animate({scrollTop:position}, 500, 'swing', ()=>{
        if(href == "#content-header") {
          document.body.setAttribute('tabindex', '0')
          document.body.focus()
          document.body.setAttribute('tabindex', '')
        }
       });
       return false;
    });
  }

}
