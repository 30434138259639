import Model from '../';

import GetWindowSize from './get-windowsize'
import GetScroll from './get-scroll'
import GetTouch from './get-touch'
import GetUA from './get-ua'
import GetVP from './get-viewport'

export default class {
  constructor() {
    this.init()
  }
  init(){

    new GetScroll()
    new GetTouch()
    new GetVP()

    document.addEventListener('DOMContentLoaded', ()=>{
      new GetUA()
      new GetWindowSize()
    })
  }
}
