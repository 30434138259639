import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.noFixed = (document.getElementsByTagName('html')[0].classList.contains('is-header-nofixed')) ? true : false
    this.state = {
      gnavshow: false
    }
    this.o = {
      html: undefined,
      header: undefined,
      gnav: undefined,
      unav: undefined,
      sublist: undefined,
      bg: undefined,
      closebtn: undefined,
      gnavbg: undefined,
      point: null,
      currentPoint: null,
      contentHeight: undefined
    }
    this.e = {
      click: (Model.info.ua.Tablet) ? 'touchend' : 'click',
      enter: (Model.info.ua.Tablet) ? 'touchend' : 'mouseenter',
      leave: (Model.info.ua.Tablet) ? 'touchend mouseleave' : 'mouseleave',
      focus: (Model.info.IE.IE11 || Model.info.IE.Edge || Model.info.ua.Firefox) ? 'focus' : 'DOMFocusIn',
      blur: (Model.info.IE.IE11 || Model.info.IE.Edge || Model.info.ua.Firefox) ? 'blur' : 'DOMFocusOut',
      speed: (Model.info.ua.Tablet) ? .5 : .5,
      delay: (Model.info.ua.Tablet) ? .2 : .2,
    }
    this.init()
    this.bindEvents()
  }
  init(){
    this.o = {
      html: document.getElementsByTagName('html')[0],
      header: this.element,
      gnav: this.element.querySelectorAll('[data-role=has-sublist]'),
      gnavlink: this.element.querySelectorAll('[data-role=has-sublist] a'),
      nosublistlink: this.element.querySelectorAll('[data-role=no-has-sublist] a'),
      unav: (!Model.info.ua.Tablet) ? this.element.querySelectorAll('[data-role=has-sublist-unav]') : this.element.querySelectorAll('[data-role=has-sublist-unav]>a'),
      sublist: this.element.querySelectorAll('[data-role="sublist"]'),
      bg: this.element.querySelector('.l-header__bg'),
      closebtn: this.element.querySelectorAll('[data-role=close-gnav-btn]'),
      gnavbg: this.element.querySelector('[data-role=gnav-bg]'),
      point: Model.state.scroll.top,
      contentHeight: undefined
    }
    if(Model.info.ua.Tablet && Model.info.ua.iOS) {
      window.addEventListener('pageshow', e =>{
        if (e.persisted && this.state.gnavshow) window.location.reload()
      })
    }
    if(!this.noFixed) this.scrollEvent()
  }
  bindEvents(){
    Model.event.on('ON_SCROLL', ()=>{
      if(!this.noFixed) this.scrollEvent()
    })
    for(let i=0; i<this.o.closebtn.length; i=i+1) {
      this.o.closebtn[i].addEventListener(this.e.click, (e)=>{
        if(Model.info.ua.Tablet) e.preventDefault()
        document.body.classList.remove('is-user-tabbing')
        this.reset()
      })
    }
    for(let i=0; i<this.o.gnav.length; i=i+1) {
      this.o.gnav[i].addEventListener(this.e.enter, (e)=>{
        e.stopPropagation()
        if(Model.info.ua.Tablet && e.target.parentNode.getAttribute('data-role') === 'has-sublist' && !this.o.header.classList.contains('is-fixed')) e.preventDefault()
        this.o.bg.classList.add('is-show')
        let h = this.o.gnav[i].querySelector('.l-gnav__subinner').clientHeight
        window.TweenMax.to(this.o.gnavbg, this.e.speed, {height: h, ease: Power4.easeOut, delay: this.e.delay})
        this.state.gnavshow = true
      })
      this.o.gnav[i].addEventListener(this.e.leave, (e)=>{
        e.stopPropagation()
        if(Model.info.ua.Tablet && e.target.parentNode.getAttribute('data-role') === 'has-sublist') e.preventDefault()
        this.o.bg.classList.remove('is-show')
        window.TweenMax.killTweensOf(this.o.gnavbg)
        if(Model.state.scroll.direction !== 'up') {
          if(this.o.point > this.o.contentHeight) {
            window.TweenMax.to(this.o.gnavbg, .1, {height: 0, ease: Power4.easeOut})
          } else {
            window.TweenMax.to(this.o.gnavbg, .01, {height: 0, ease: Power4.easeOut, delay: this.e.delay})
          }
        } else {
          window.TweenMax.to(this.o.gnavbg, .1, {height: 0, ease: Power4.easeOut, delay: .1})
          window.TweenMax.killTweensOf(this.o.gnavbg)
          this.reset()
        }
        this.state.gnavshow = false
      })
    }
    for(let i=0; i<this.o.gnavlink.length; i=i+1) {
      this.o.gnavlink[i].addEventListener(this.e.focus, (e)=>{
        this.o.bg.classList.add('is-show')
        let h = this.o.gnavlink[i].closest('[data-role=has-sublist]').querySelector('.l-gnav__subinner').clientHeight
        window.TweenMax.to(this.o.gnavbg, this.e.speed, {height: h, ease: Power4.easeOut, delay: this.e.delay})
        this.state.gnavshow = true
      })
      this.o.gnavlink[i].addEventListener(this.e.blur, (e)=>{
        this.o.bg.classList.remove('is-show')
        window.TweenMax.killTweensOf(this.o.gnavbg)
        window.TweenMax.to(this.o.gnavbg, this.e.speed, {height: 0, ease: Power4.easeOut, delay: this.e.delay})
        this.state.gnavshow = false
      })
    }
    for(let i=0; i<this.o.nosublistlink.length; i=i+1) {
      this.o.nosublistlink[i].addEventListener(this.e.click, (e)=>{
        if(Model.info.ua.Tablet) {
          this.o.bg.classList.remove('is-show')
          this.o.gnavbg.style.height = '0px'
          this.state.gnavshow = false
        }
      })
    }
    if(Model.info.ua.Tablet) {
      this.o.header.addEventListener(this.e.click, ()=>{
        this.reset(true)
        this.state.gnavshow = false
      })
    }
    for(let i=0; i<this.o.unav.length; i=i+1) {
      this.o.unav[i].addEventListener(this.e.enter, (e)=>{
        if(Model.info.ua.Tablet) this.reset()
        if(Model.info.ua.Tablet) e.preventDefault()
        e.stopPropagation()
        this.o.bg.classList.add('is-show')
        this.state.gnavshow = true
      })
      if(Model.info.ua.Tablet) {
        let unavlink = this.o.unav[i].nextElementSibling.querySelectorAll('[target=_blank]')
        for(let j=0; j<unavlink.length; j=j+1) {
          unavlink[j].addEventListener(this.e.enter, (ev)=>{
            ev.stopPropagation()
          })
        }
      }
      this.o.unav[i].addEventListener(this.e.leave, (e)=>{
        if(Model.info.ua.Tablet) e.preventDefault()
        e.stopPropagation()
        this.o.bg.classList.remove('is-show')
        this.state.gnavshow = false
      })
      this.o.unav[i].addEventListener(this.e.focus, (e)=>{
        this.o.bg.classList.add('is-show')
        this.state.gnavshow = true
      })
      this.o.unav[i].addEventListener(this.e.blur, (e)=>{
        this.o.bg.classList.remove('is-show')
        this.state.gnavshow = false
      })
    }
    this.o.bg.addEventListener(this.e.click, (e)=>{
      if(Model.info.ua.Tablet) e.preventDefault()
      e.stopPropagation()
      this.reset()
      this.state.gnavshow = false
    })
    document.body.addEventListener("mousemove", (e)=>{
      if(!Model.info.IE.IE11 && document.body.classList.contains('is-user-tabbing')) {
        this.reset()
        this.state.gnavshow = false
      }
    })
  }
  reset(nodelay){
    if(!nodelay) this.o.header.classList.add('is-closing')
    this.o.bg.classList.remove('is-show')
    window.TweenMax.to(this.o.gnavbg, .2, {height: 0, ease: Power4.easeOut})
    setTimeout(()=>{
      this.o.header.classList.remove('is-closing')
    }, 300)
  }
  scrollEvent(){
    this.o.point = Model.state.scroll.top
    if(Model.info.isSP) {
      this.o.contentHeight = this.o.header.clientHeight
    } else if(Model.state.scroll.direction === 'up') {
      this.o.contentHeight = 40
    } else if(Model.state.scroll.direction === 'down') {
      this.o.contentHeight = 78
    }
    if(this.o.point > this.o.contentHeight) {
      this.o.html.classList.add('is-header-fixed')
      this.o.header.classList.add('is-fixed')
    } else {
      this.o.html.classList.remove('is-header-fixed')
      this.o.header.classList.remove('is-fixed')
    }
    if(Model.state.scroll.direction === 'up') {
      this.o.header.classList.remove('is-hide')
      this.o.header.classList.add('is-show')
      if(!Model.info.isSP) {
        if(this.o.point > this.o.contentHeight) {
          this.offsetControl(true)
        } else {
          this.offsetControl()
        }
      }
    } else if(Model.state.scroll.direction === 'down') {
      this.o.header.classList.add('is-hide')
      this.o.header.classList.remove('is-show')
      if(!Model.info.isSP) {
        this.offsetControl()
      }
    }
    if(Model.state.scroll.moving && !this.o.currentPoint) this.o.currentPoint = this.o.point
  }
  offsetControl(offset){
    if(offset) {
      for(let i=0; i<this.o.sublist.length; i=i+1) {
        this.o.sublist[i].style.top = ''
      }
      this.o.gnavbg.style.top = ''
    } else if(!isNaN(offset)) {
      for(let i=0; i<this.o.sublist.length; i=i+1) {
        this.o.sublist[i].style.top = offset + 'px'
      }
      this.o.gnavbg.style.top = offset + 'px'
    } else {
      for(let i=0; i<this.o.sublist.length; i=i+1) {
        this.o.sublist[i].style.top = this.o.header.clientHeight - this.o.point + 1 + 'px'
      }
      this.o.gnavbg.style.top = this.o.header.clientHeight - this.o.point + 1 + 'px'
    }
  }
}
