import Model from '../model'
import Util from '../util'

export default class {
  constructor(element, config){
    this.element = element
    this.config = config
    this.o = {
      offset: undefined,
      checkline: undefined,
      parts: undefined
    }
    this.flag = false
    this.scrolltop = document.documentElement.scrollTop || document.body.scrollTop
    this.mag = 1
    this.delayspeed = 1
    this.bindEvents()
    this.init()
  }
  init(){

    this.checkMagnification()

    this.o = {
      offset: this.element.getBoundingClientRect().top + this.scrolltop,
      checkline: Model.state.scroll.bottom,
      parts: (Util.isKeyInObject(this.config,'has-children')) ? this.element.querySelectorAll('[data-role=animation-parts]') : undefined
    }

    this.element.style.opacity = 0

    if(Util.isKeyInObject(this.config,'has-children')) {
      for(let i = 0; i < this.o.parts.length; i = i+1){
        this.o.parts[i].style.transform = 'translateY(50px)'
        this.o.parts[i].style.opacity = 0
      }
    } else {
      this.element.style.opacity = 'translateY(50px)'
    }
    if(this.element.getAttribute('data-onloaded') === 'true') {
      window.addEventListener('load',()=> this.fadeIn())
    } else if(this.element.getAttribute('data-onloaded') === 'domready') {
      this.fadeIn()
    } else if(!this.flag) {
      this.checkScroll()
    }
  }
  bindEvents(){
    Model.event.on('ON_SCROLL', ()=>{
      if(!this.flag) this.checkScroll()
    })
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.checkMagnification()
    })
  }
  checkMagnification(){
    this.mag = (Model.info.isSP) ? .7 : .9
  }
  checkScroll(){
    if(this.o.offset < Model.state.scroll.top + (Model.state.windowsize.h * this.mag)) {
      this.fadeIn()
    }
  }
  fadeIn(){
    if(Util.isKeyInObject(this.config,'has-children')) {
      window.TweenMax.to(this.element, .4, {opacity: 1})
      window.TweenMax.staggerTo(this.o.parts, .4, {ease: Power1.easeOut, opacity: 1, transform: 'translateY(0)'}, .1)
    } else {
      window.TweenMax.to(this.element, .4, {ease: Power1.easeOut, opacity: 1, transform: 'translateY(0)'})
    }
    this.flag = true
  }
}
