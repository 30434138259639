import Model from '../model'

// -----------------------------------------------------------------
//
// @method Module.touchclass()
// タッチ中は is-touch を付与
// -----------------------------------------------------------------

export default class {
  constructor(){
    this.init()
  }
  init() {
    $('a,button').on('touchstart', function(e) {
      $(this).addClass('is-touch')
    })
    .on('touchend', function(e) {
      $(this).removeClass('is-touch')
    })
  }


}
