import ChangeViewport from './change-viewport'
import SmoothScroll from './smooth-scroll'
import TouchClass from './touch-class'
import KeydownTabClass from './keydown-tab-class'
import AddUAClass from './add-ua-class'
import SetBtnBlank from './set-btn-blank'

import HeaderFunction from './header'

export default class {
  constructor(){
    new ChangeViewport()
    new SmoothScroll()
    new TouchClass()
    new KeydownTabClass()
    new AddUAClass()

    new HeaderFunction()

    $('.c-btn-list__item__link.r-blank').each((i, e) => {
      new SetBtnBlank(e)
    });
  }
}
