import Util from '../util'
export default class {
  constructor(){
    // TOPの演出
    this.$stage = $('.top-opening')
    this.$logo = this.$stage.find('.top-opening__logo')
    this.$catch = this.$stage.find('.top-opening__catch')
    this.$text = this.$stage.find('.top-opening__txt')

    this.$slider = $('.top-slider__cnt')
    this.$scrollBtn = $('.top-scroll__btn')

    this.lang = document.documentElement.getAttribute('lang')
    this.cookie = (this.lang === 'ja') ? 'toppagevisit' : 'topenpagevisit'
    this.visitFlag = (Util.getCookie(this.cookie) > 0) ? true : false

    this.init()
    this.bindEvents()
  }
  init(){

    this.$slider.slick({
      autoplay: false,
      autoplaySpeed: 7000,
      arrows: true,
      fade: true,
      dots: true,
      infinite: true,
      speed: 800,
      pauseOnHover: false,
      dotsClass: 'top-slider__indicator',
      prevArrow: '<a class="top-slider__arrow r-prev" href="#"></a>',
      nextArrow: '<a class="top-slider__arrow r-next" href="#"></a>'
    })

    this.playFlg = true
    this.$ctrlBtn = $('<button class="top-slider__ctrl-btn"><span class="top-slider__ctrl-btn__start is-hide">再生</span><span class="top-slider__ctrl-btn__pause">停止</span></button>')

    this.$ctrlBtn.prependTo('.top-slider__cnt')

    this.$ctrlBtn.ready(()=> {
      this.$ctrlStartBtn = $('.top-slider__ctrl-btn__start')
      this.$ctrlPauseBtn = $('.top-slider__ctrl-btn__pause')
    })

    this.$ctrlBtn.on('click', ()=> {
      if (this.playFlg) {
        this.playFlg = false
        this.$ctrlStartBtn.removeClass('is-hide')
        this.$ctrlPauseBtn.addClass('is-hide')
        this.$scrollBtn.addClass('is-paused')
        this.$slider.slick('slickPause')
      } else {
        this.playFlg = true
        this.$ctrlStartBtn.addClass('is-hide')
        this.$ctrlPauseBtn.removeClass('is-hide')
        this.$scrollBtn.removeClass('is-paused')
        this.$slider.slick('slickPlay')
      }
    })
    this.judge()
    this.scrollFadein()
  }
  bindEvents(){
  }
  judge(){
    if(this.visitFlag) {
      this.skipSplash()
    } else {
      Util.setCookie(this.cookie, 1, 1)
      if(this.lang !== 'en') {
        this.playSplash()
      } else {
        $('.top-opening__catch-en').addClass('is-active');
        this.playSplashEn()
      }
    }
  }
  skipSplash(){
    this.$stage.hide()
    this.$slider.addClass('is-on')
    this.$slider.slick('slickPlay')
  }
  playSplashEn(){

    setTimeout(()=> {
      TweenMax.to(this.$logo, 1, {
        opacity: 1,
        ease: Power2.easeOut
      });
    }, 400);

    setTimeout(()=> {
      TweenMax.staggerTo('.top-opening__catch span', 0.6, {
        opacity: 1,
        left: 0,
        ease : Power2.easeOut
      }, 0.1);
    }, 1400);

    setTimeout(()=> {
      $('.top-opening__catch-en').removeClass('is-active');
    }, 3500);

    setTimeout(()=> {
      TweenMax.to(this.$logo, 1, {
        opacity: 0,
        ease: Power4.easeOut
      });
      TweenMax.to(this.$catch, 1, {
        opacity: 0,
        ease: Power4.easeOut
      });
    }, 3500);

    setTimeout(()=> {
      this.$text.fadeIn(1000, ()=> {
        setTimeout(()=> {
          this.$text.fadeOut(1000);
        }, 3000);
      });
    }, 4000);

    setTimeout(()=> {
      this.$stage.fadeOut(2000)
      this.skipSplash()
    }, 8000)
  }
  playSplash(){
    setTimeout(()=> {
      TweenMax.to(this.$logo, 1, {
        opacity: 1,
        ease: Power2.easeOut
      })
    }, 400)

    setTimeout(()=> {
      this.$catch.addClass('is-on')
    }, 800)

    setTimeout(()=> {
      TweenMax.staggerTo('.top-opening__catch span', 0.6, {
        opacity: 1,
        left: 0,
        ease : Power2.easeOut
      }, 0.1)
    }, 1400)

    setTimeout(()=> {
      TweenMax.to(this.$logo, 1, {
        opacity: 0,
        ease: Power4.easeOut
      })
      TweenMax.to(this.$catch, 1, {
        opacity: 0,
        ease: Power4.easeOut
      })
    }, 3000)

    setTimeout(()=> {
      this.$text.fadeIn(1000, ()=> {
        setTimeout(()=> {
          this.$text.fadeOut(1000)
        }, 3000)
      })
    }, 4000)

    setTimeout(()=> {
      this.$stage.fadeOut(2000)
      this.skipSplash()
    }, 8000)

  }
  scrollFadein(){

    // inview
    this.$window = $(window)
    this.windowHeight = this.$window.height()
    this.$elements = $('.top-section__card-list')
    this.$items    = $('.top-section__card-list__itm')

    this.$items.css( {
      opacity: 0
    })

    this.$items.each((i)=> {
      $(this).css({
        WebkitTransform  : 'translateY(25px)',
        transform        : 'translateY(25px)'
      })
    })
    setTimeout(()=> {

      this.$window.on('scroll', ()=>{
        this.currentScroll = this.$window.scrollTop()
        this.$elements.each((i,el)=> {
          let thisPosition  = $(el).offset().top

          if (this.currentScroll > thisPosition - this.windowHeight + this.windowHeight / 6) {
            let itm = $(el).find('.top-section__card-list__itm')
            itm.each((j,e)=> {
              setTimeout(()=> {
                $(e).css({
                  opacity          : 1,
                  WebkitTransform  : 'translateY(0)',
                  transform        : 'translateY(0)',
                  WebkitTransition : 'opacity .8s ease .25s, transform .7s ease .25s',
                  transition       : 'opacity .8s ease .25s, transform .7s ease .25s'
                })
              }, j * 250)
            })
          }
        })
      })
      this.$window.trigger('scroll')
    }, 150)
  }
}
