import Model from '../model'

export default class {
// -----------------------------------------------------------------
//
// @method Components.expandAccordion()
// pattern属性にもとづいて入力を制御する
// -----------------------------------------------------------------
  constructor(element) {
    this.element = element
    this.$el = {
      btn: undefined
    }
    this.init()
  }

  init() {
    this.$el = {
      btn: $(this.element).find('[aria-expanded]')
    }
    this.bindEvents()
  }
  bindEvents() {
      this.$el.btn.on('click', (e)=>{
      this.show(e)
    })
  }
  show(e){
    if(e.target.getAttribute('aria-expanded') === 'true') {
      e.target.setAttribute('aria-expanded', 'false')
      e.target.nextElementSibling.setAttribute('aria-hidden', 'true')
    } else {
      e.target.setAttribute('aria-expanded', 'true')
      e.target.nextElementSibling.setAttribute('aria-hidden', 'false')
    }
  }
}
