export default class {
  constructor(element, config){
    this.element = element
    this.init()
    this.bindEvents()
  }
  init(){
    let notice = document.createElement('span')
    notice.classList.add('u-accessibility-notice')
    notice.textContent = '音声ブラウザではプレイヤーは再生と停止のみ可能です。'
    let player = this.element.querySelector('.jstream-eqPlayer')
    this.element.insertBefore(notice, player)
  }
  bindEvents(){}
}
