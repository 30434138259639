import Model from '../model'

export default class {
  constructor(element) {
    this.element = $(element)
    this.body = document.querySelector('.p-storybook')
    this.wrapper = document.querySelector('.p-storybook-wrapper')
    this.content = document.querySelector('.p-storybook-content')
    this.modal = document.querySelector('.p-storybook-modal')
    this.window = $(window)
    this.$el = {
      button: $('[data-role=window-close]'),
      prev: $('[data-role=page-prev]'),
      next: $('[data-role=page-next]'),
      image: $('[data-image=storybook-image]'),
      pages: $('[data-page=storybook-pages]')
    }

    this.checkDevice()
    this.bindEvents()
  }

  //SP閲覧時、縦横アテンション画面の表示、非表示の処理
  checkDevice() {
    if(Model.info.ua.Mobile) {
      this.body.classList.add('is-sp')
      this.setBookContentSP()
    } else {
      this.setBookContentPC()
    }
  }

  setBookContentPC() {
    this.element.turn({
      width: 1134,
      height: 417,
      autoCenter: true,
      duration: 1500,
      gradients: true
    })
    this.window.on('load', () => {
      this.wrapper.classList.add('is-active')
    })
  }

  setBookContentSP() {
    this.element.turn({
      width: 540,
      height: 198,
      autoCenter: true,
      duration: 1500,
      gradients: true
    })
    this.window.on('load', () => {
      this.wrapper.classList.add('is-active')
    })
  }

  bindEvents() {
    let maxPage = this.element.turn('pages'),
        content = $('.p-storybook-content'),
        prevBtn = $('[data-role=page-prev]'),
        nextBtn = $('[data-role=page-next]')

    this.element.bind('start', (e, pageObject, corner) => {
      this.content.classList.add('is-transition-start')
    })

    this.element.bind("turning", (event, page, view) => {

      if (page >= 2) {
        $('.fixed').css('display', 'block')
        prevBtn.css('opacity',1)
      } else {
        prevBtn.css('opacity', 0)
      }

      if (page === maxPage) {
        nextBtn.css('opacity', 0)
      } else {
        nextBtn.css('opacity', 1)
      }
    })

    this.element.bind('turned', (event, page, view) => {
      if(page === 1 || page === maxPage) {
        $('.fixed').css('display', 'none')
      }
    })

    this.$el.button.on('click', (e) => {
      this.closeWindow()
    })

    this.$el.prev.on('click', (e) => {
      this.prevPage()
    })

    this.$el.next.on('click', (e) => {
      this.nextPage()
    })
  }

  closeWindow() {
    if (/Chrome/i.test(navigator.userAgent)) {
      window.close()
    } else {
      window.open('about:blank', '_self').close()
    }
  }

  prevPage() {
    let page = this.element.turn('page')
    if (page >= 2) {
      this.$el.prev.css('opacity',1)
    } else {
      this.$el.prev.css('opacity', 0)
    }
    this.element.turn('previous')
  }

  nextPage() {
    let page = this.element.turn('page')
    if(page === this.maxpage) {
      this.$el.next.css('opacity', 0)
    } else {
      this.$el.next.css('opacity', 1)
    }
    this.element.turn('next')
  }
}
