export default class {
  constructor(element, config){
    this.el = {
      link : element
    }
    this.init()
  }
  init(){
    this.el.link.addEventListener('click', (e)=>{
      e.preventDefault()
      e.stopPropagation()

      let href = e.currentTarget.getAttribute('href')
      if (href) window.open(href);
    })
  }
}