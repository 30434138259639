import Model from '../../model';

export default class {
  constructor() {
    this.info = {
      ua: undefined,
      IE: undefined
    }
    this.checkUA()
  }

  checkUA() {
    this.info.ua = this.getUserAgent(window.navigator.userAgent.toLowerCase())
    this.info.IE = this.getIsLegacyIE(window.navigator.appVersion.toLowerCase())
    Model.onCheckUA(this.info)
  }

  // -----------------------------------------------------------------
  //
  // @method getUserAgent()
  // @method getIsLegacyIE()
  //
  // -----------------------------------------------------------------

  getUserAgent(u) {
    return {
      Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1) || u.indexOf("ipad") != -1 || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1) || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) || u.indexOf("kindle") != -1 || u.indexOf("silk") != -1 || u.indexOf("playbook") != -1,
      Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1) || u.indexOf("iphone") != -1 || u.indexOf("ipod") != -1 || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1) || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) || u.indexOf("blackberry") != -1,
      iOS: u.indexOf("iphone") != -1 || u.indexOf("ipod") != -1 || u.indexOf("ipad") != -1,
      AndroidLegacy:u.search(/Android 4.[012]/) != -1 || u.search(/android 4.[012]/) != -1,
      lteA4:u.search(/Android 4.[01234]/) != -1 || u.search(/android 4.[01234]/) != -1,
      lteA5:u.search(/Android 5.[01234]/) != -1 || u.search(/android 5.[01234]/) != -1,
      Firefox: u.indexOf("firefox") != -1,
      SafariLegacy:u.indexOf("version/9") != -1 && u.indexOf("safari") != -1 && u.indexOf("mac os x") != -1,
      Safari:u.indexOf("safari") != -1 && u.indexOf("mac os x") != -1 && u.indexOf("chrome") == -1
    }
  }
  getIsLegacyIE(u) {
    return {
      IE11:(u.indexOf('msie') >= 0 || u.indexOf('trident') >= 0),
      IE8:u.indexOf("msie 6.") != -1 || u.indexOf("msie 7.") != -1 || u.indexOf("msie 8.") != -1,
      Edge:(u.indexOf('windows') > -1 && u.indexOf('edge') > -1)
    }
  }
}
