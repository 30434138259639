import Model from '../model'

export default class {
  constructor(element, config){
    this.el = {
      button : element.querySelectorAll('[role=button]'),
      sublist : element.querySelectorAll('[role="sublistlink"]')
    }
    this.e = {
      focus: (Model.info.IE.IE11 || Model.info.IE.Edge || Model.info.ua.Firefox) ? 'focus' : 'DOMFocusIn',
      blur: (Model.info.IE.IE11 || Model.info.IE.Edge || Model.info.ua.Firefox) ? 'blur' : 'DOMFocusOut'
    }
    this.init()
  }
  init(){
    this.bindEvents()
  }
  bindEvents(){
    for(let i=0; i < this.el.button.length; i=i+1) {
      this.el.button[i].addEventListener('click', (e)=>{
        let isOpen = e.currentTarget.getAttribute('aria-expanded')
        if(isOpen === 'true') {
          e.currentTarget.setAttribute('aria-expanded', 'false')
        } else {
          e.currentTarget.setAttribute('aria-expanded', 'true')
        }
      })
    }
    for(let i=0; i < this.el.sublist.length; i=i+1) {
      this.el.sublist[i].addEventListener(this.e.blur, (e)=>{
        e.currentTarget.closest('[role="sublist"]').previousElementSibling.setAttribute('aria-expanded', 'false')
      })
      this.el.sublist[i].addEventListener(this.e.focus, (e)=>{
        e.currentTarget.closest('[role="sublist"]').previousElementSibling.setAttribute('aria-expanded', 'true')
      })
    } 
  }
}
