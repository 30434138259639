import Util from '../util'
import Model from '../model'

export default class {

    constructor(element, config) {
      this.element = element
      this.config = (config) ? config : null
      this.type = (Util.isKeyInObject(this.config,'type')) ? this.config['type'] : 'news'
      this.parmArr =
        {
          'newscategory': Util.escapeHTML(Util.getParam('newscategory')),
          'newsyear': Util.escapeHTML(Util.getParam('newsyear'))
        }
      this.filiterArr = new Object
      //ディレクトリ変更のため、ディレクトリをタイプによって振り分け
      this.directory = (this.type === 'news') ? 'newsrelease' : (this.type === 'updates') ? 'news' : this.type
      this.category = (Util.isKeyInObject(this.config,'category')) ? this.config['category'] : 'all'
      this.allJSONdata =　[]
      this.el = {
          year: this.element.querySelector('[data-role="news-list-category"]'),
          category: this.element.querySelector('[data-role="news-list-category"]')
      }
      this.init()
    }

    init() {
        this.GetData('news_archive')
        this.ControlData()
        this.DOMChange()
        this.initCategory()
    }

    GetData(otherCategory) {
        $.ajax({
            type: "GET",
            scriptCharset: 'utf-8',
            dataType: 'json',
            url: (!otherCategory) ?
                Util.escapeHTMLliteral`/${this.directory}/json/${this.category}.json`:
                Util.escapeHTMLliteral`/${this.directory}/archive/json/${otherCategory}.json`,
            async: false,
            success: (data) => {
                if(!otherCategory) {
                    //最初に表示するために全データをフィルター後として扱う
                    let filterJSONdata = data[0][(!otherCategory) ? this.type : otherCategory]
                    this.allJSONdata = filterJSONdata.concat(this.tempJSONdata)
                    this.ExportYearSelectData()
                    this.ExportArticleData(this.allJSONdata)
                } else {
                    //全データをthis.allJSONdataにいれる
                    this.tempJSONdata = data[0][(!otherCategory) ? this.type : otherCategory]
                    // console.log(this.tempJSONdata)
                    this.GetData()
                }
            },
            error: (xhr, textStatus, errorThrown)　=> {
              // console.log(xhr);
              // console.log(textStatus);
              // console.log(errorThrown);
                let ArticleDOMareaID = document.getElementById('r-NEWSdata-DOMarea')
                let Create_p_Error = document.createElement('p')
                Create_p_Error.appendChild(document.createTextNode('データの読み込みに失敗しました。'))
                ArticleDOMareaID.appendChild(Create_p_Error)
            }
        });
    }

    ExportArticleData(filterJSONdata) {
        let ArticleDOMareaID = document.getElementById('r-NEWSdata-DOMarea')
        let ArticleDOMarea = $('[data-role="newsData-domArea"]')
        ArticleDOMarea.empty()
        if (filterJSONdata.length) {
            for (var i = 0; i < filterJSONdata.length; i++) {
                let Create_li = document.createElement('li')
                let Create_div_Heading = document.createElement('div')
                let Create_div_Content = document.createElement('div')
                let Create_span_Date = document.createElement('span')
                let Create_span_Number = document.createElement('span')
                let Create_span_Sentence = document.createElement('span')
                let Create_a_Link = document.createElement('a')
                //setAttribute
                Create_li.setAttribute('class', 'c-news-fulllist__output-element')
                Create_div_Heading.setAttribute('class', 'c-news-fulllist__output-element__heading')
                Create_div_Content.setAttribute('class', 'c-news-fulllist__output-element__content')


                Create_span_Date.setAttribute('data-name', 'date')
                Create_span_Date.setAttribute('class', 'c-news-fulllist__output-element__date')
                Create_span_Number.setAttribute('data-name', 'number')
                Create_span_Number.setAttribute('class', 'c-news-fulllist__output-element__number')
                Create_span_Sentence.setAttribute('data-name', 'Sentence')
                Create_span_Sentence.setAttribute('class', 'c-txt-link__href c-news-fulllist__output-element__href')
                Create_span_Sentence.setAttribute('class', 'c-txt-link__href c-news-fulllist__output-element__href')
                Create_a_Link.setAttribute('href', filterJSONdata[i].link)
                Create_a_Link.setAttribute('class', 'c-txt-link is-usual c-news-fulllist__output-element__link')
                if (filterJSONdata[i].link.indexOf('.pdf') != -1) {
                    Create_a_Link.setAttribute('class', 'c-txt-link is-usual r-document__pdf c-news-fulllist__output-element__link')
                    Create_a_Link.setAttribute('data-gtm', 'compdf')
                    Create_a_Link.setAttribute('target', '_blank')
                } else if (filterJSONdata[i].linktype.indexOf('_blank') !== -1 || filterJSONdata[i].linktype.indexOf('_crossover') !== -1) {
                  Create_a_Link.setAttribute('class', 'c-txt-link is-usual r-document__jump c-news-fulllist__output-element__link')
                  Create_a_Link.setAttribute('target', '_blank')
                } else {
                  Create_span_Sentence.setAttribute('class', 'c-txt-link__href c-news-fulllist__output-element__href')
                }
                //テキストをappend
                for(var j in filterJSONdata[i]['category']) {
                  let Temp_span_Category = document.createElement('span')
                  Temp_span_Category.setAttribute('data-name', 'category')
                  Temp_span_Category.setAttribute('class', 'c-news-fulllist__output-element__category')
                  Temp_span_Category.textContent = filterJSONdata[i]['category'][j]
                  Create_div_Heading.appendChild(Temp_span_Category)
                }
                Create_span_Date.appendChild(document.createTextNode(filterJSONdata[i]['date']))
                Create_span_Number.appendChild(document.createTextNode(filterJSONdata[i]['entryno']))
                if (filterJSONdata[i].link.indexOf('.pdf') != -1){
                  if (filterJSONdata[i]['pdfsize'] !== ''){
                    Create_span_Sentence.appendChild(document.createTextNode(filterJSONdata[i].title + '（' + filterJSONdata[i]['pdfsize'] + '）'))
                  } else {
                    Create_span_Sentence.appendChild(document.createTextNode(filterJSONdata[i].title))
                  }
                } else {
                    Create_span_Sentence.appendChild(document.createTextNode(filterJSONdata[i].title))
                }
                //入れ子化
                Create_a_Link.appendChild(Create_span_Sentence)
                Create_div_Content.appendChild(Create_a_Link)
                // Create_div_Heading.appendChild(Create_span_Category)
                Create_div_Heading.appendChild(Create_span_Date)
                Create_div_Heading.appendChild(Create_span_Number)
                Create_li.appendChild(Create_div_Heading)
                Create_li.appendChild(Create_div_Content)
                ArticleDOMareaID.appendChild(Create_li)
            }
        //選択した年・イクラに該当するデータがない
        } else {
            let Create_li = document.createElement('li')
            Create_li.setAttribute('class', 'c-news-fulllist__output-element')
            let up_span = Create_li.appendChild(document.createElement('span'))
            Create_li.appendChild(document.createElement('br'))
            let low_span = Create_li.appendChild(document.createElement('span'))
            up_span.appendChild(document.createTextNode('ニュースが見つかりません。'))
            low_span.appendChild(document.createTextNode('条件を指定しなおすなどしてご覧ください。'))
            ArticleDOMareaID.appendChild(Create_li)
        }
    }

    //年選択レンダリング
    ExportYearSelectData() {
        let YearList = []
        for (let i = 0; i < this.allJSONdata.length; i++){
            YearList.push(this.allJSONdata[i].year);
        }
        let UseYearList = YearList.filter( (x, i, self) => {
            return self.indexOf(x) === i;
        })
        UseYearList.sort( (a, b) => {
            if (a < b) return 1
            if (a > b) return -1
            return 0
        })
        let YearSelectDOMarea = document.getElementById('r-year_SelectBox')
        let Create_select = document.createElement('select')
        Create_select.setAttribute('name', 'year')
        let Create_option_Placeholder = document.createElement('option')
        Create_option_Placeholder.appendChild(document.createTextNode('選択してください'))
        Create_option_Placeholder.setAttribute('value', '')
        Create_option_Placeholder.selected = true
        Create_option_Placeholder.disabled = true
        Create_option_Placeholder.setAttribute('style', 'display: none;')
        Create_select.appendChild(Create_option_Placeholder)
        let Create_option_AllYear = document.createElement('option')
        Create_option_AllYear.appendChild(document.createTextNode('すべて'))
        Create_option_AllYear.setAttribute('value', '')
        Create_select.appendChild(Create_option_AllYear)
        for (let i = 0; i < UseYearList.length; i++) {
            let Create_option = document.createElement('option')
            Create_option.setAttribute('value', UseYearList[i])
            Create_option.appendChild(document.createTextNode(UseYearList[i]))
            //入れ子化
            Create_select.appendChild(Create_option)
        }
        //入れ子化
        YearSelectDOMarea.appendChild(Create_select)
    }

    ControlData(){
        $('#r-parse_form').change( () => {
            let year_val = $('[name=year]').val()
            let category_all = $('#radio_allcategory')
            let category_val = $('[name=category]:checked').val()
            let ir_category_val = $('[name=ir_category]:checked').val()
            this.filiterArr['newscategory'] = (category_val === 'IR情報' && ir_category_val) ? $('[name=ir_category]:checked').attr('id').replace('radio_','') :
              (category_val === 'all' || category_val === '' || category_val === undefined) ? '' : $('[name=category]:checked').attr('id').replace('radio_','')
            this.filiterArr['newsyear'] = (year_val === 'all' || year_val === '' || year_val === undefined || year_val === null) ? '' : year_val
            this.setCategory()

            //IRの時のclassつけ外し
            if (category_val == 'IR情報') {
                $('[data-role="IR_active"]').addClass('active')
                if (ir_category_val === null || ir_category_val === undefined ){
                    //表示非表示で使う
                    this.Select_IR_Child = false
                //IRの小カテゴリ選択時
                } else {
                    //表示非表示で使う
                    this.Select_IR_Child = true
                }
            //IR以外の時
            } else {
                $('input:radio[name="ir_category"]').prop('checked', false)
                $('[data-role="IR_active"]').removeClass('active')
                //表示非表示で使う
                this.Select_IR_Child = false
            }
            //2015年以前の時のclassつけ外し
            if(parseInt(year_val) <= 2015) {
                this.el.category.classList.add('is-disabled')
                $('input:radio[name="ir_category"]').prop('checked', false)
                $('[data-role="IR_active"]').removeClass('active')
                category_all.prop('checked',true)
                category_val = ''
                //表示非表示で使う
                this.Select_IR_Child = false
            } else {
                this.el.category.classList.remove('is-disabled')
            }
            //共通の引数たち
            const dataAll = this.allJSONdata
            let YearStr = year_val
            let CategoryStr = category_val
            let IRCategoryStr = ir_category_val
            // console.log(CategoryStr)
            // 選択した年・イクラの組み合わせで条件分岐
            if (category_val === 'all' || category_val === '' || category_val === undefined) {
                if (year_val === 'all' || year_val === '' || year_val === undefined || year_val === null){
                    return this.DataFilter_A(dataAll)
                }
                return this.DataFilter_C(dataAll, YearStr)
            } else {
                if (year_val === 'all' || year_val === '' || year_val === undefined || year_val === null){
                    return this.DataFilter_B(dataAll, CategoryStr, IRCategoryStr)
                }
                return this.DataFilter_D(dataAll, YearStr, CategoryStr, IRCategoryStr)
            }
        })
    }



    //年すべて・カテゴリすべて
    DataFilter_A(dataAll) {
        let filterJSONdata = dataAll
        // console.log('Filter_A')
        return this.ExportArticleData(filterJSONdata)
    }

    //年すべて・カテゴリ選択
    DataFilter_B(dataAll, CategoryStr, IRCategoryStr) {
            let filterJSONdata = dataAll.filter( (item, index) => {
            let ItemCategoryStr = item.category
            let ItemIRCategoryStr = item.ircategory
            if (this.Select_IR_Child == true) {
                if (~ItemCategoryStr.indexOf(CategoryStr) && ~ItemIRCategoryStr.indexOf(IRCategoryStr))
                return true
            } else {
                if (~ItemCategoryStr.indexOf(CategoryStr))
                return true
            }
        })
        // console.log('Filter_B')
        return this.ExportArticleData(filterJSONdata)
    }

    //年選択・カテゴリすべて
    DataFilter_C(dataAll, YearStr) {
            let filterJSONdata = dataAll.filter( (item, index) => {
            let ItemYearStr = item.year
            if (~ItemYearStr.indexOf(YearStr))
                return true
        })
        // console.log('Filter_C')
        return this.ExportArticleData(filterJSONdata)
    }

    //年選択・カテゴリ選択
    DataFilter_D(dataAll, YearStr, CategoryStr, IRCategoryStr) {
            let filterJSONdata = dataAll.filter( (item, index) => {
            let ItemYearStr = item.year
            let ItemCategoryStr = item.category
            let ItemIRCategoryStr = item.ircategory
            if (this.Select_IR_Child == true) {
                if (~ItemYearStr.indexOf(YearStr) && ~ItemCategoryStr.indexOf(CategoryStr) && ~ItemIRCategoryStr.indexOf(IRCategoryStr))
                return true
            } else {
                if (~ItemYearStr.indexOf(YearStr) && ~ItemCategoryStr.indexOf(CategoryStr))
                return true
            }
        })
        // console.log('Filter_D')
        return this.ExportArticleData(filterJSONdata)
    }


    DOMChange() {
        //年プルダウン
        $('.r-year_export').click( () => {
            if ($('.r-year_SelectList').attr('aria-hidden') === 'true') {
                $('.r-year_SelectList').attr('aria-hidden', 'false')
            } else {
                $('.r-year_SelectList').attr('aria-hidden', 'true')
            }
            $('.r-acc-arrow').toggleClass('opened')
        })
        //年の選択
        $('.r-year_select').on('click', e => {
            $('.r-acc-arrow').toggleClass('opened')
            let btn_txt = $(e.currentTarget).text()
            $('.r-year_export').empty()
            let EscapedExportDOM = Util.escapeHTMLliteral`${btn_txt}<span class="r-acc-arrow"></span>`
            $('.r-year_export').append(EscapedExportDOM)
            let btn_val = $(e.currentTarget).attr('data-value')
            $('#r-year_SelectBox').val(btn_val).change()
            $('.r-year_SelectList').attr('aria-hidden', 'true')
            // console.log(btn_val)
        })
        //年の選択（SP）
        $('#r-parse_form').change( () => {
            let year_val = $('[name=year]').val()
            if (year_val == '') {
                year_val = 'すべて'
            }
            if (Model.info.isSP == true) {
                $('.r-year_export').empty()
                let EscapedExportSPDOM = Util.escapeHTMLliteral`${year_val}`
                $('.r-year_export').append(EscapedExportSPDOM)
            }
        })
        //イクラ
        $('.r-radio_label').on('click', e => {
            let radio_name = $(e.currentTarget).find('input').attr('name')
            if (radio_name == 'ir_category') {
                if ($('#r-Label_IR').hasClass('clicked')) {
                    $('.r-radio_IRCat_label').removeClass('clicked')
                } else {
                    $('.r-radio_label').removeClass('clicked')
                }
            } else {
                $('.r-radio_label').removeClass('clicked')
            }
            $(e.currentTarget).addClass('clicked')
        })
    }

    initCategory(){
      if(this.parmArr) {
        for(let item in this.parmArr) {
          if(item === 'newscategory' && this.parmArr[item]) {
            if(this.parmArr[item].match(/ir/)) $('#radio_ir').prop('checked',true)
            $('#radio_' + this.parmArr[item]).prop('checked',true)
          } else if(item === 'newsyear') {
            if(this.parmArr[item] === 'null') {
              $('#r-year_SelectBox option').eq(0).attr('selected', true)
            } else {
              $('#r-year_SelectBox select').val(this.parmArr[item])
            }
          }
        }
        $('#r-parse_form').trigger('change')
      }
    }

    setCategory(){

      let setParam = '', count = 0

      for(let item in this.filiterArr) {
        if(this.filiterArr[item] && (parseInt(this.filiterArr['newsyear']) > 2015 || !this.filiterArr['newsyear'])) {
          setParam += (count > 0) ? '&' : '?'
          setParam += item + '=' + this.filiterArr[item]
          count = count + 1
        }
      }
      if(parseInt(this.filiterArr['newsyear']) <= 2015) setParam = '?newsyear=' + this.filiterArr['newsyear']
      if(setParam) history.pushState(null, null, setParam)

    }

}
