import Model from '../model'

export default class {
// -----------------------------------------------------------------
//
// @method Components.replaceDeviceimage()
// ビューポート判定でPC / SPの画像差し替え
// -----------------------------------------------------------------
  constructor() {
    this.init()
  }

  init() {
    this.images = document.querySelectorAll('[data-module=replace-deviceimage]')
    this.replace()
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.replace()
    })
  }

  replace(){
    for(let image of this.images) {
      let getSrc = image.getAttribute('src'),
          setSrc = undefined

      if(Model.state.windowsize.w < 768) {
        setSrc = getSrc.replace(/_pc./g, '_sp.')
        image.setAttribute('src', setSrc)
      } else {
        setSrc = getSrc.replace(/_sp./g, '_pc.')
        image.setAttribute('src', setSrc)
      }
    }
  }
}
