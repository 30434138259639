export default class {
  constructor(el){
    this.$el = $(el)
    this.init()
  }

  init(){
    this.$el.append('<span class="c-btn-list__external__icon"></span>');
  }
}
