import Model from '../model'

// -----------------------------------------------------------------
//
// @method Module.keydownTabClass()
// Tabキー（Firefoxは矢印キー）押下時、状態クラスを付与
// -----------------------------------------------------------------

export default class {
  constructor(){
    this.init()
  }
  init() {
    this.bindEvents()
  }
  bindEvents(){
    window.addEventListener('keydown', (e)=> {
      this.handleUseTab(e)
    })
    document.body.addEventListener("mousemove", (e)=>{
      if(!Model.info.IE.IE11 && document.body.classList.contains('is-user-tabbing')) {
        document.body.classList.remove('is-user-tabbing')
      }
    })
  }
  handleUseTab(e) {
    if(!document.body.classList.contains('is-user-tabbing')) {
      if(Model.info.ua.Firefox) {
        //キャレットブラウザ対応
        if ([9,37,38,39,40].includes(e.keyCode)) {
          document.body.classList.add('is-user-tabbing')
        }
      } else {
        if ([9].includes(e.keyCode)) {
          document.body.classList.add('is-user-tabbing')
        }
      }
    }
  }

}
