import ExpandedAccodion from './expand-accordion'
import ScrollHeader from './scroll-header'
import ScrollPageTop from './scroll-pagetop'
import FocusWithIn from './focus-within'
import IsCurrentLocalnavi from './is-current-localnavi'
import IsCurrentFooternavi from './is-current-footernavi'
import IsCurrentGlobalnavi from './is-current-globalnavi'
import ChangeRowtable from './change-rowtable'
import ChangeRowtableType2 from './change-rowtable-type2'
import ReplaceDeviceimage from './replace-deviceimage'
import AdjustRetinaImage from './adjust-retina-image'
import SwitchTabs from './switch-tab'
import ToggleBtn from './toggle-btn'
import SliderContentsTop from './slider-contents-top'
import SliderContents from './slider-contents'
import SliderContentsBooks from './slider-contents-book'
import SliderContentsPresentation from './slider-contents-presentation'
import ModalWindow from './modal-window'
import StorybookContents from './storybook-contents'
import WindowOpen from './window-open'
import HeightLine from './height-line'
import OverflowScroll from './overflow-scroll'
import FilterPulldown from './filter-pulldown'
import ValidateInput from './validate-input'
import RenderNewsList from './render-news-list'
import RenderNewsListSimple from './render-news-list-simple'
import RenderNoticesListSimple from './render-notices-list'
import ScrollFadeinAmimation from './scroll-fadein-animation'
import QAIncrement from './qa-increment'
import HoverChildren from './hover-children'
import JStreamBaseSeting from './jstream-base-setting'

export default class {
  constructor(){
    this.module = document.querySelectorAll('[data-module]')
    this.moduleKeys()
  }
  moduleKeys(){
    Array.prototype.forEach.call(this.module, element => {
      const MODULE_KEY = element.getAttribute('data-module')
      const MODULE_CONF = element.getAttribute('data-config') || null
      const Components = require(`./${MODULE_KEY}`).default
      const CONFIG = (this.isJSON(MODULE_CONF)) ? JSON.parse(MODULE_CONF) : (MODULE_CONF) ? MODULE_CONF : null
      if (Components !== void 0)
        return new Components(element, CONFIG)
      })
  }
  isJSON(arg) {
    return /{/.test(arg)
  }
}
