import Model from '../model'

export default class {
  constructor(){
    this.adjustTablet()
  }

// -----------------------------------------------------------------
//
// @method adjustTablet()
//
// -----------------------------------------------------------------

  adjustTablet(){
    if(Model.info.ua.Tablet) {
      let vp = document.querySelector('[name=viewport]')
      vp.setAttribute('content','width=1200')
    }
  }

}
