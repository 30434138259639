import Model from '../model'
import Util from '../util'

export default class {
// -----------------------------------------------------------------
//
// @method Components.RenderNewsListSimple()
// JSON読み込みリストへ変換
// -----------------------------------------------------------------
  constructor(element, config) {
    this.element = element
    this.config = (config) ? config : null
    this.type = (Util.isKeyInObject(this.config,'type')) ? this.config['type'] : 'news'
    //ディレクトリ変更のため、ディレクトリをタイプによって振り分け
    this.directory = (this.type === 'news') ? 'newsrelease' : (this.type === 'updates') ? 'news' : this.type
    this.showCategory = (Util.isKeyInObject(this.config,'showcategory')) ? this.config['showcategory'] : false
    this.category = (Util.isKeyInObject(this.config,'category')) ? this.config['category'] : 'all'
    this.number = (Util.isKeyInObject(this.config,'number')) ? parseInt(this.config['number']) : 5
    this.hiddenFlag = (Util.isKeyInObject(this.config,'hiddenflag')) ? Boolean(this.config['hiddenflag']) : false
    this.filter = Array
    this.el = {
      list: undefined,
      object: undefined,
      template: undefined
    }
    this.init()
  }

  init() {
    this.el = {
      list: undefined,
      object: Util.getJSON(`/${this.directory}/json/${this.category}.json`)[0][this.type],
      template: ''
    }
    this.filter = (Util.isKeyInObject(this.config,'filter')) ? this.config['filter'] : null
    if(this.el.object === undefined || !this.el.object || this.el.object.length <= 0) this.renderNoResult()
    if(this.el.object.length < 5) this.number = this.el.object.length
    if(this.type === 'blog') this.element.classList.add('r-blog')
    if(this.el.object) (this.type !== 'blog') ? this.render() : this.renderBlog()
    this.bindEvents()
  }
  bindEvents() {}
  renderNoResult() {
    this.el.template += Util.escapeHTMLliteral`
      <li class="r-itm">現在、関連するニュースはありません。</li>
    `
    this.element.innerHTML = this.el.template
    return false
  }
  render() {
    let count = 0, number = 0
    for(let i in this.el.object) {
      let current_category_id = (this.el.object[i]['current_category_id']) ? this.el.object[i]['current_category_id'] : []
      if(this.category === 'all' && (this.filter === null || this.filter.some(val => current_category_id.some(catval => catval === val)))) {
        number++
      }
    }
    if(this.number > number && this.category === 'all') this.number = number
    for(let i in this.el.object) {
      let current_category_id = (this.el.object[i]['current_category_id']) ? this.el.object[i]['current_category_id'] : []
    if(this.filter === null || this.filter.some(val => current_category_id.some(catval => catval === val))) {
      let hiddentoppageflag = (this.hiddenFlag) ? this.el.object[i].hiddenflagrenderlist_com : false
      if(!hiddentoppageflag) {
        let categoryTemplate = (this.showCategory || this.category === 'ir') ? (this.el.object[i].ircategory[0]) ? `<span class="r-notice">${this.el.object[i].ircategory}</span>` : '' : (this.showCategory || (this.category === 'all' && !this.filter)) ? (this.el.object[i].category[0]) ? `<span class="r-notice">${this.el.object[i].category}</span>` : '' : ''
        let newsNumTemplate = (this.type === 'news') ? `<span data-name="number" class="c-news-fulllist__output-element__number">${this.el.object[i].entryno}</span>` : ''
        let blank = (this.el.object[i].pdfsize !== '' || this.el.object[i].linktype !== '' || this.el.object[i].linktype === '_crossover') ? ' target="_blank"': ''
        let hasPDF = (this.el.object[i].pdfsize !== '' || this.el.object[i].link.match(/.pdf$/)) ? ' r-document__pdf': ''
        let eventPDF = (this.el.object[i].pdfsize !== '' || this.el.object[i].link.match(/.pdf$/)) ? ' data-gtm="compdf"': ''
        let pdfsize = (this.el.object[i].pdfsize !== '') ? '（' + this.el.object[i].pdfsize + '）' : ''
        if(this.el.object[i].link !== '') {
          this.el.template += Util.escapeHTMLliteral`
            <li class="r-itm">
              <a href="${this.el.object[i].link}"${blank}${eventPDF}>
                ${categoryTemplate}
                <span class="r-date">${this.el.object[i].date}</span>
                ${newsNumTemplate}
                <p class="r-hdg${hasPDF}">${this.el.object[i].title.replace(/@@/g,'')}${pdfsize}</p>
              </a>
            </li>
          `
        } else {
          this.el.template += Util.escapeHTMLliteral`
            <li class="r-itm">
              ${categoryTemplate}
              <span class="r-date">${this.el.object[i].date}</span>
              ${newsNumTemplate}
              <p class="r-hdg${hasPDF}">${this.el.object[i].title.replace(/@@/g,'')}${pdfsize}</p>
            </li>
          `
        }
      } else {
        this.number = this.number + 1
      }
      if(count >= this.number - 1) {
        this.element.innerHTML = this.el.template
        return false
      }
      count++
    }
  }
  }
  renderBlog(){
    let count = 0;
    for(let i in this.el.object) {
      if(this.filter === null || this.filter.some(val => this.el.object[i].category_id.some(catval => catval === val))) {
        let categorySrc = ''
        for(let j=0; j < this.el.object[i].category.length; j=j+1) {
          categorySrc += `<span class="c-pnl-list__itm__category ${this.el.object[i].category_id[j]}" style="order:${j+1}">${this.el.object[i].category[j]}</span>`
        }
        this.el.template += Util.escapeHTMLliteral`
          <div class="c-pnl-list__itm">
          <a href="${this.el.object[i].link}" class="c-pnl-list__itm__box">
            <div class="c-pnl-list__itm__info">
              ${categorySrc}
              <span class="c-pnl-list__itm__date">${this.el.object[i].date}</span>
            </div>
            <h3 data-role="height-line-item" class="c-pnl-list__itm__ttl--simple">${this.el.object[i].title.replace(/@@/g,'')}</h3>
            <!-- /.c-pnl-list__itm__ttl -->
            <p class="c-pnl-list__itm__txt">
            ${this.el.object[i].description}
            </p>
            <figure class="c-pnl-list__itm__img__frm r-ar-16-9">
              <img src="${this.el.object[i].image}" alt="" class="c-pnl-list__itm__img">
            </figure>
          </a>
          </div>
          <!-- /.c-pnl-list__itm -->
        `
        if(count >= this.number - 1) {
          this.element.innerHTML = this.el.template
          return false
        }
        count++
      }
    }
  }
}

