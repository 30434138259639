export default class {
  constructor(element, config){
    this.$el = {
      tabs: $('[data-module=switch-tab]')
    }
    this.$el.tablist = this.$el.tabs.find('[role=tablist]')
    this.$el.tabpanel = this.$el.tabs.find('[role=tabpanel]')
    this.$el.tab = this.$el.tabs.find('[role=tab]')
    this.bindEvents()
  }
  bindEvents(){
    this.$el.tab.on('click', e=> {
      this.tabSwitch($(e.currentTarget))
    })
    .on('keyup', e=> {
      if (e.which === 13 ) {
        this.tabSwitch($(e.currentTarget))
      }
    })
  }
  tabSwitch(e){
    let number = e.attr('aria-controls'),
        target = e.attr('aria-labeledby')
        this.$el.tab.removeClass('is-active')
        e.addClass('is-active')
        this.$el.tabpanel.attr('aria-hidden', 'true').find('a').attr('tabindex','-1')
        $('[aria-labeledby=' + number + ']').attr({'aria-hidden': 'false'}).find('a').removeAttr('tabindex')
  }
}
