import Util from '../util'

export default class {
  constructor(element, config){
    this.el = {
      html: document.getElementsByTagName('html')[0],
      button : element
    }
    this.config = (config) ? config : null
    this.expandtarget = Util.isKeyInObject(this.config,'expandtarget') ? Util.escapeHTMLliteral`[data-role="${this.config['expandtarget']}"]` : undefined
    this.expandstate = Util.isKeyInObject(this.config,'expandstate') ? Util.escapeHTMLliteral`${this.config['expandstate']}` : undefined
    this.init()
  }
  init(){
    this.el.button.setAttribute('tabindex', '0')
    this.el.button.addEventListener('keyup', e=>{
      if(e.keyCode === 13) this.toggle(e.currentTarget)
    })
    this.el.button.addEventListener('click', e=>{
      this.toggle(e.currentTarget)
    })
  }
  toggle(target){
    let isOpen = target.getAttribute('aria-expanded')
    let nextElm = (this.expandtarget) ? Util.nextAll(target, this.expandtarget)[0] : target.nextElementSibling;

    if(isOpen === 'true') {
      target.setAttribute('aria-expanded', 'false')
      if(this.expandstate) this.el.html.classList.remove(this.expandstate)
      if(nextElm.getAttribute('aria-hidden') === 'false') {
        nextElm.setAttribute('aria-hidden', 'true')
      }
    } else {
      target.setAttribute('aria-expanded', 'true')
      if(this.expandstate) this.el.html.classList.add(this.expandstate)
      if(nextElm.getAttribute('aria-hidden') === 'true') {
        nextElm.setAttribute('aria-hidden', 'false')
      }
    }
  }
}
