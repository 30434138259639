import Util from '../util'
import Model from '../model'

export default class {
  constructor(element, config){
    this.element = element
    this.config = (config) ? config : null
    this.device = (Util.isKeyInObject(this.config,'device')) ? this.config.device : null
    this.bindEvents()
    this.init()
  }
  init(){
    setTimeout(()=>{
      if(Model.info.isSP && this.device === 'PC') {
        this.reset()
      } else {
        this.adjust()
      }
    },10)
  }
  bindEvents(){
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.init()
    })
  }
  reset(){
    this.element.style.width = 'auto'
  }
  adjust(){
    let image = new Image()
    image.src = this.element.getAttribute('src')
    this.element.style.width = `${image.width / 2}px`
  }
}
