import Model from '../model'
import Util from '../util'

// -----------------------------------------------------------------
//
// @method Form.changeRowtable()
// SP時のテーブル th / td 縦横入れ替え機能
// -----------------------------------------------------------------

export default class {
  constructor(element,config) {
    this.element = element
    this.init()
  }
  init(){
    this.el = {
      row: this.element.querySelectorAll('tr'),
      head: this.element.querySelectorAll('.c-tbl__head')
    }
    this.array = []
    this.getHead()
    this.render()
  }
  bindEvents(){}
  getHead(){
    for(let i=0; i < this.el.head.length; i=i+1) {
      this.array.push(this.el.head[i].textContent.replace(/\n/g,'').replace(/\s/g,''))
    }
  }
  render(){
    for(let i=0; i < this.el.row.length; i=i+1) {
      let target = this.el.row[i].getElementsByTagName('td')
      for(let j=0; j < this.array.length; j=j+1) {
        if(target[j]) target[j].setAttribute('aria-label',this.array[j])
      }
    }
  }
}
