export default class {
  constructor(el) {
    this.$el = $(el)
    this.init()
  }
  init() {
    this.$el.slick({
      arrows: true,
      fade: true,
      dots: false,
      infinite: false,
      accessibility: true,
      prevArrow: '<a class="c-slider__arrow r-prev" href="#"></a>',
      nextArrow: '<a class="c-slider__arrow r-next" href="#"></a>'
    })

    $('.c-slider__arrow.r-prev').addClass('is-inactive');

    this.$el.on('afterChange', function(slick, currentSlide){
      if (currentSlide.currentSlide == 0) {
        $('.c-slider__arrow.r-prev').addClass('is-inactive');
      } else {
        $('.c-slider__arrow.r-prev').removeClass('is-inactive');
      }
      if (currentSlide.currentSlide == $('.c-slider__item').length - 1) {
        $('.c-slider__arrow.r-next').addClass('is-inactive');
      } else {
        $('.c-slider__arrow.r-next').removeClass('is-inactive');
      }
    });
  }
}
