import Model from '../../model'

export default class {
  constructor() {
    this.scroll = {
      top: undefined,
      bottom: undefined,
      moving: false,
      direction: undefined
    }
    this.currentScroll = -1
    this.bindEvents()
    this.checkPoint()
    this.checkDirection()
  }

  bindEvents() {
    let timer = false
    window.addEventListener('scroll',()=> {
      this.checkPoint()
      this.checkDirection()
      if (timer !== false) {
        this.scroll.moving = true
        clearTimeout(timer);
      }
      timer = setTimeout(()=> {
        this.scroll.moving = false
        Model.onScrollEvent(this.scroll)
      }, 200);
    })
  }

  checkPoint() {
    this.scroll.top = window.pageYOffset
    this.scroll.bottom = window.pageYOffset + Model.state.windowsize.h
    Model.onScrollEvent(this.scroll)
  }

  checkDirection() {
    if(this.currentScroll > window.pageYOffset) {
      this.scroll.direction = 'up';
    } else if(this.currentScroll < window.pageYOffset) {
      this.scroll.direction = 'down';
    }
    this.currentScroll = window.pageYOffset
    Model.onScrollEvent(this.scroll)
  }

}
