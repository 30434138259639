import Model from '../model'

// -----------------------------------------------------------------
//
// @method Form.changeRowtableType2()
// SP時のテーブル th / td 縦横入れ替え機能
// ■ サンプルコード（CSSは未適用・高さ合わせのみ）
// <div data-module="change-rowtable-type2" class="c-tbl">
//   <div class="c-tbl__row">
//     <div class="c-tbl__head">カテゴリー</div>
//     <div class="c-tbl__data">調味料類</div>
//     <div class="c-tbl__data">レトルト食品</div>
//     <div class="c-tbl__data">缶詰</div>
//     <div class="c-tbl__data">瓶詰</div>
//     <div class="c-tbl__data">その他加工食品</div>
//   </div>
//   <div class="c-tbl__row">
//     <div class="c-tbl__head">件数</div>
//     <div class="c-tbl__data">0件</div>
//     <div class="c-tbl__data">0件</div>
//     <div class="c-tbl__data">0件</div>
//     <div class="c-tbl__data">0件</div>
//     <div class="c-tbl__data">0件</div>
//   </div>
// </div>
// -----------------------------------------------------------------

export default class {
  constructor() {
    this.$el = undefined
    this.tableData = []
    this.tableHeight = []
    this.getElement()
    this.bindEvents()
    this.init()
  }
  getElement(){
    this.$el = {
      row: $('[data-module=change-rowtable-type2]').find('.c-tbl__row')
    }
    this.$el.row.each((n,el)=> {
      this.tableData[n] = this.$el.row.eq(n).find('[class^="c-tbl__"]')
    })
  }
  bindEvents(){
    Model.event.on('ON_WINDOW_RESIZE', ()=>{
      this.init()
    })
  }
  init(){
    this.tableHeight = []
    this.$el.row.find('[class^="c-tbl__"]').height('')
    this.getHeight()
  }
  getHeight(){
    if(Model.info.isSP) {
      this.tableData.forEach((value, index)=> {
        for(let i=0; i < value.length; i=i+1){
          if(!this.tableHeight[i]){
            this.tableHeight[i] = value[i].offsetHeight
          } else {
            this.tableHeight[i] = (this.tableHeight[i] < value[i].offsetHeight) ? value[i].offsetHeight : this.tableHeight[i]
          }
        }
      })
      this.setHeight()
    }
  }
  setHeight(){
  // TODO: jQueryを使用しないようにリファクタ予定
    this.$el.row.each((n,el)=> {
      for(let i=0; i < this.tableHeight.length; i=i+1){
        $(this.tableData[n][i]).outerHeight(this.tableHeight[i])
      }
    })
    console.log('OK');
  }
}
